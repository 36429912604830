import { createSlice } from "@reduxjs/toolkit";
import {getClients, getClient, submitPaymentForm} from "./ClientActions";

const clientSlice = createSlice({
    name: 'Client',
    initialState: { loading: false, error: false, success: false, clients:[], client:{}, url:''},
    reducers: {
        clearError(state, action) {
            state.error = false;
        },
        clearSuccess: (state, action) => {
            state.success = false;
        },
        clearUrl: (state, action) => {
            state.url = '';
        }
    },
    extraReducers: (builder) => {
        // getClientList
        builder
            .addCase(getClients.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getClients.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.success = true;
                state.clients = action.payload.clients
            })
            .addCase(getClients.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
        // getClient
        builder
            .addCase(getClient.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getClient.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.success = true;
                state.client = action.payload.client
            })
            .addCase(getClient.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
        // submitPaymentForm
        builder
            .addCase(submitPaymentForm.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(submitPaymentForm.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.success = true;
                state.url = action.payload.url
            })
            .addCase(submitPaymentForm.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

const clientAction = clientSlice.actions;

export {
    clientAction,
    clientSlice,
};