import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const getClients = createAsyncThunk("get/getClients", async (data,{rejectWithValue}) => {
    try {
        const response = await axios.get('https://fit4sure.in/api/getClients',{withCredentials:true});
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});

const getClient = createAsyncThunk("get/getTrainer", async (id,{rejectWithValue}) => {
    try {
        const response = await axios.get(`https://fit4sure.in/api/getClient/${id}`,{withCredentials:true});
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});

const submitPaymentForm = createAsyncThunk("post/submitPaymentForm", async (data,{rejectWithValue}) => {
    try {
        const config={headers:{"Content-Type":"application/json"}};
        const response = await axios.post('https://fit4sure.in/api/newPayment',data,config);
        return response.data;
    }catch(error) {
        return rejectWithValue(error.response.data.message);
    }
});

export {getClients, getClient, submitPaymentForm};