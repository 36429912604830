import React from 'react';
import { CheckCircle } from '@mui/icons-material';
import  styles from './ThankYou.module.sass';


const ThankYou = () => {
  return (
    <div className={styles.thankContainer}>
      <CheckCircle className={styles.icon} />
      <h1>Thank You!</h1>
      <p className={styles.subtitle}>We will contact you in 24 hours</p>
    </div>
  )
}

export default ThankYou