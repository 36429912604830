import React,{useEffect,useState} from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { useSelector, useDispatch } from 'react-redux'
import { getClients } from '../../../Store/ClientActions'
import styles from './Client.module.sass'
import { Link } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Info from './Info';

const Client = () => {
    const [currentID, setCurrentID] = useState(null);
    const dispatch = useDispatch();
    const { clients} = useSelector((state) => state.client);

    useEffect(() => {
        dispatch(getClients("hello"));
    }, [dispatch]);


    const columns = [
        {
            field: "name",
            headerName: "Name",
            minWidth: 100,
            flex: 0.2
        },
        {
          field: "email",
          headerName: "Email",
          minWidth: 100,
          flex: 0.3,
        },
        {
            field: "contactNumber",
            headerName: "Contact",
            minWidth: 100,
            flex: 0.2
        },
        {
            field: "gender",
            headerName: "Gender",
            minWidth: 100,
            flex: 0.2
        },
        {
            field: "country",
            headerName: "Country",
            minWidth: 100,
            flex: 0.2
        },
        {
            field: "state",
            headerName: "State",
            minWidth: 100,
            flex: 0.2
        },
        {
            field: "city",
            headerName: "City",
            minWidth: 100,
            flex: 0.2
        },
        {
            field: 'view',
            headerName: 'View',
            minWidth: 100,
            flex: 0.2,
            sortable: false,
            renderCell: (params) => {
                return (<>
                    <Link onClick={()=>{setCurrentID(params.row.id)}}>
                        <OpenInNewIcon></OpenInNewIcon>
                    </Link>
                </>
                );
            }
        },
    ];
   
    

    const rows = [];
    clients && clients.forEach(element => {
        rows.unshift({
            id: element._id,
            email: element.email,
            name: element.name,
            contactNumber: element.phone,
            gender: element.gender,
            country: element.country,
            state: element.state,
            city: element.city
        });

    });


  return (
    <div className={styles.section}>
        {currentID!=null&&<Info setCurrentID={setCurrentID} currentID={currentID}></Info>}
        <div className={styles.heading}>Clients</div>
        <div className={styles.dataGrid}>
        <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
      />
        </div>
    </div>
  )
}

export default Client