import React from "react";
import Plan from "./Plan";
import Faq from "./Faq";

const Pricing = () => {
    return (
        <>
            <Plan />
            <Faq />
        </>
    );
};

export default Pricing;
