import React, { useEffect } from 'react'
import styles from './Info.module.sass'
import { useSelector, useDispatch } from 'react-redux'
import { getClient } from '../../../../Store/ClientActions'

const Info = ({ setCurrentID, currentID }) => {

    const dispatch = useDispatch();
    const { client } = useSelector((state) => state.client);



    useEffect(() => {
        dispatch(getClient(currentID));
    }, [dispatch, currentID]);



    const handleChildClick = (event) => {
        event.stopPropagation();
    };

   const convertDate = (milliseconds) =>{
    const date = new Date(+milliseconds);
    const formattedDate = date.toLocaleDateString();
    return formattedDate;
   }


    return (
        <div className={styles.section} onClick={() => { setCurrentID(null) }}>
            <div className={styles.detail} onClick={handleChildClick}>
                <div>
                    <h2>Client Information</h2>
                    <div className={styles.info}>
                        <div>
                    <p>
                        <strong>Name:</strong> {client.name}
                    </p>
                    <p>
                        <strong>Gender:</strong> {client.gender}
                    </p>
                    <p>
                        <strong>Age:</strong> {client.age}
                    </p>
                    <p>
                        <strong>Country:</strong> {client.country}
                    </p>
                    <p>
                        <strong>State:</strong> {client.state}
                    </p>
                    <p>
                        <strong>City:</strong> {client.city}
                    </p>
                    <p>
                        <strong>Contact Number:</strong> {client.phone}
                    </p>
                    <p>
                        <strong>Email:</strong> {client.email}
                    </p>
                    <p>
                        <strong>Joining Since:</strong> {client.createdAt && convertDate(client.createdAt)}
                    </p>
                   
                    </div>
                    <div>
                    <p>
                        <strong>Payment History: </strong>
                    </p>
                    <div className={styles.payment}>
                        { client.paymentHistory &&
                            client.paymentHistory.map((item, index) => {
                                return <div className={styles.paymentCard}>
                                    <div>
                                        <strong>Price:</strong> {item.price}
                                    </div>
                                    <div>
                                        <strong>Type:</strong> {item.type}
                                    </div>
                                    <div>
                                        <strong>Status:</strong> {item.status}
                                    </div>
                                    <div>
                                        <strong>Joining:</strong> {item.createdAt&&convertDate(item.createdAt)}
                                    </div>
                                    <div>
                                        <strong>Hear From:</strong> {item.hearPlace}
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Info