import React from "react";
import Hero from "./Hero";

const Lifestyle = () => {
    return (
        <>
            <Hero />
        </>
    );
};

export default Lifestyle;
