import React, { useState, useEffect } from 'react'
import styles from "./PaymentForm.module.sass";
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux'
import { submitPaymentForm } from '../../Store/ClientActions'
import { useParams } from 'react-router-dom'
import { getPlan } from '../../Store/PlanActions';
import { PlanAction } from '../../Store/PlanReducer';
import { useAlert } from 'react-alert';
import { GetCountries, GetState, GetCity } from 'react-country-state-city';
import { Link } from 'react-router-dom';



const PaymentForm = () => {
    const [countryid, setCountryid] = useState(0);
    const [stateid, setStateid] = useState(0);
    const [cityid, setCityid] = useState(0);

    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);


    const alert = useAlert();
    const { id } = useParams();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        name: '',
        country: '',
        age: '',
        email: '',
        gender: '',
        city: '',
        phone: '',
        type: '',
        price: 0,
        state: '',
        hearPlace: '',
        acceptPolicy: false,
        weight: 0
    });

    const { Plan, error } = useSelector((state) => state.plan);
    const { success, url } = useSelector((state) => state.client);

    useEffect(() => {
        if (error) {
            alert.error(error);
        }
        dispatch(getPlan(id));
    }, [dispatch, error]);

    useEffect(() => {
        if (success) {
            window.location.href = url;
            dispatch(PlanAction.clearSuccess());
            dispatch(PlanAction.clearUrl());
        }
    }, [dispatch, success, url]);

    useEffect(() => {
        GetCountries().then((result) => {
            setCountryList(result);
        });
    }, []);



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    



    const handleSubmit = (e) => {
        e.preventDefault();
        formData.type = Plan.title;
        formData.price = Plan.price*Plan.month;
        dispatch(submitPaymentForm(formData));
    };

    return (
        <div className={cn("container", styles.container)} id='paymentForm'>
            {Object.keys(Plan).length > 0 &&
                <div className={styles.wrap}>

                    <form onSubmit={handleSubmit} className={styles.form}>
                        <div className={styles.view}>
                            <div>
                                <label className={styles.label}>
                                    <div>Name</div>
                                    <input type="text" name="name" value={formData.name} onChange={handleChange} required />
                                </label>
                                <label className={styles.label}>
                                    <div>Age</div>
                                    <input type="text" name="age" value={formData.age} onChange={handleChange} required />
                                </label>

                                <label className={styles.label}>
                                    <div>Email</div>
                                    <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                                </label>

                                <label className={styles.label}>
                                    <div>Gender</div>
                                    <select name="gender" value={formData.gender} onChange={handleChange} required>
                                        <option value="">Select Gender</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="other">Other</option>
                                    </select>
                                </label>

                                <label className={styles.label}>
                                    <div>Weight in Kg</div>
                                    <input type="number" name="weight" value={formData.weigth} onChange={handleChange} required />
                                </label>
                            </div>
                            <div>
                                <label className={styles.label}>
                                    <div>Country</div>
                                    <select
                                        onChange={(e) => {
                                            const country = countryList[e.target.value]; //here you will get full country object.
                                            setCountryid(country.id);
                                            GetState(country.id).then((result) => {
                                                setStateList(result);
                                            });
                                            setFormData((prevData) => ({ ...prevData, 'country': country.name }));
                                        }}
                                        value={countryid}
                                        required
                                    >
                                        {countryList.map((item, index) => (
                                            <option key={index} value={index}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                </label>

                                <label className={styles.label}>
                                    <div>State</div>
                                    <select
                                        onChange={(e) => {
                                            const state = stateList[e.target.value];
                                            setStateid(e.target.value);
                                            GetCity(countryid, state.id).then((result) => {
                                                setCityList(result);
                                            });
                                            setFormData((prevData) => ({ ...prevData, 'state': state.name }));
                                        }}
                                        value={stateid}
                                        required
                                    >
                                        {stateList.map((item, index) => (
                                            <option key={index} value={index}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                </label>


                                <label className={styles.label}>
                                    <div>City</div>
                                    <select
                                        onChange={(e) => {
                                            const city = cityList[e.target.value]; //here you will get full city object.
                                            setCityid(e.target.value);
                                            setFormData((prevData) => ({ ...prevData, 'city': city.name }));
                                        }}
                                        value={cityid}
                                        required
                                    >
                                        {cityList.map((item, index) => (
                                            <option key={index} value={index}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                </label>


                                <label className={styles.label}>
                                    <div>Contact Number</div>
                                    <input type="text" name="phone" value={formData.phone} onChange={handleChange} required />
                                </label>

                                <label className={styles.label}>
                                    <div>Where do you hear about us?</div>
                                    <select name="hearPlace" value={formData.hearPlace} onChange={handleChange} required>
                                        <option value="">Select Place</option>
                                        <option value="Facebook">Facebook</option>
                                        <option value="Instagram">Instagram</option>
                                        <option value="Google">Google</option>
                                        <option value="Friend">Friend</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </label>

                            </div>
                        </div>
                        <label className={styles.policy}>
                        <input type="checkbox" name="acceptPolicy" onChange={handleChange} required />
                            <div>I accept the <Link to='/fit4Sure-termsAndConditions'>terms and conditions</Link></div> 
                        </label>
                        <div>
                            <button type="submit" className={cn('button', styles.button)}>Pay {Plan.price*Plan.month}</button>
                        </div>
                    </form>
                </div>
            }
        </div>
    )
}

export default PaymentForm
